import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'
Vue.use(VueRouter)

const routes = [
  {
    path: '/*',
    name: 'Layout',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
