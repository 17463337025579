<template>
  <div id="footer">
    <div id="foot_main">
      <div class="copy_left">
        <div class="copy_box">
          <p class="phone">电话：4009-2135-88</p>
          <p>周一至周日 9:00-18:00（仅收市话费）</p>
        </div>
        <div class="copy_txt">
          <span><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery"> 沪ICP备15013022号-1 | </a>  </span>
          <span><img src="../../../public/img/Public Security.png" style="margin-bottom: -2px;"> <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902002178">沪公网安备31010902002178号 | </a> </span>
          <span style="cursor: pointer;" @click="showImg()"> 互联网药品信息服务资格证编号(沪)-经营性-2021-0161 </span>
        </div>
      </div>
      <div class="copy_right">
        <div class="copy_box">
          <img src="../../../public/img/logo.png" style="height: 100%;">
        </div>
        <div class="copy_txt">
          Copyright © 2014-{{ endTime }} 上海越光医疗科技有限公司 版权所有
        </div>
      </div>
    </div>
    <!-- 回顶按钮 -->
    <div @click="toTop()"><div v-if="topFixed==true" class="back_top" /></div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
    >
      <img
        src="../../../public/img/zs.png"
        width="100%"
      >
    </el-dialog></div>

</template>

<style scoped>

#footer{
  background-color:whitesmoke;
	height: 20%;
  width: 100%;
	padding: 2% 0px;
  margin: 0px auto;
  color: #666666;
}
#foot_main{
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.copy_box{
  height: 100px;
}
.copy_box img{
  height: 100%;
}
.copy_txt{
  height: 30px;
  line-height: 30px;
}
.phone{
  margin-bottom: 1vh;
  font-size: 2.1em;
  font-weight: bold;
  font-style: italic;
  color: #666666;
}
/* 回顶按钮 */
.back_top{
  z-index: 2000;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 15px;
  position:fixed;
  right: 3vh;
  bottom:3vh;
  height: 50px;
  width: 50px;
  background-image: url('../../../public/img/top.png');
  background-repeat: no-repeat;
  background-size:100% 100%;
  -webkit-animation: move 0.1s linear 0s;
          animation: move 0.1s linear 0s;
}
@-webkit-keyframes move {
  from{
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  to{
    -webkit-transform:translateY(0px);
            transform:translateY(0px)
  }
}
@keyframes move {
  from{
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  to{
    -webkit-transform:translateY(0px);
            transform:translateY(0px)
  }
}
span a{
  color: #666666;
  text-decoration: none;
}
@media (max-width:660px){
  #foot_main{
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .phone{
    font-size: 1.5em;
  }
  span{
    display: block;
  }
  .copy_box img{
    width: 30%;
  }
  .copy_box,.copy_txt{
    font-size: 0.7em;
    height: auto;
  }
  .back_top{
    border-radius: 10px;
    right: 15px;
    bottom:20px;
    height: 40px;
    width: 40px;
  }
  span img{
    height: 15px;
    width: 15px;
  }
}
</style>
<script>
export default {
  name: 'Layout',
  data() {
    return {
      topFixed: false,
      dialogVisible: false,
      endTime: new Date().getFullYear()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.watchScroll)
  },
  methods: {
    // 控制底部回到顶部的按钮
    watchScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 200) {
        this.topFixed = true
      } else {
        this.topFixed = false
      }
    },
    showImg() {
      console.log(1)
      this.dialogVisible = true
    },
    toTop() {
      var timer = setInterval(function() {
        var scTop = document.documentElement.scrollTop || document.body.scrollTop
        var speed = Math.floor(-scTop / 6)
        document.documentElement.scrollTop = document.body.scrollTop = scTop + speed
        if (scTop === 0) {
          clearInterval(timer)
        }
      }, 10)
    }
  }
}
</script>
