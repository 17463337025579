<template>
  <div>
    <!-- 一、首部 -->
    <div id="header" :class="navBarFixed == true ? 'navBarWrap' :''">
      <!-- 1.logo部分 -->
      <div id="header_logo">
        <img v-if="navBarFixed==true" src="../../../public/img/logo.png" @click="logoUrl()">
        <img v-if="navBarFixed==false" src="../../../public/img/logo_white.png" @click="logoUrl()">
      </div>
      <!-- 手机模式 小图标 -->
      <div class="open_list" @click="dialog = true">
        <img src="../../../public/img/open.png" alt="">
      </div>
      <!-- 抽屉部分 -->
      <el-drawer
        title="越光医疗"
        :visible.sync="dialog"
        custom-class="demo-drawer"
        size="60%"
        :append-to-body="true"
      >
        <div class="drawer_main">
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/home'}">首 页</router-link></div></el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/introduce'}">简 介</router-link></div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/bigthumb'}">越光大拇指</router-link></div></el-col>
          </el-row> -->
          <!-- <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/product'}">产 品</router-link></div></el-col>
          </el-row> -->
          <!-- <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/supports'}">支 持</router-link></div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/news'}">资 讯</router-link></div></el-col>
          </el-row> -->
          <!-- <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()"><router-link :to="{path:'/appdow'}">下 载</router-link></div></el-col>
          </el-row> -->
          <!-- <el-row>
            <el-col :span="24">
              <div class="grid-content">
                <el-popover
                  trigger="hover"
                >
                  <el-button slot="reference" round>购买</el-button>
                  <ul>
                    <el-popover
                      trigger="hover"
                      placement="left"
                    >
                      <li slot="reference" class="li_buy"><span class="span_buy">动态心电检查</span></li>
                      <div class="buy_img"><img src="../../../public/img/xd.png"></div>
                    </el-popover>
                    <el-popover
                      trigger="hover"
                      placement="left"
                    >
                      <li slot="reference" class="li_buy"><span class="span_buy">越光大拇指®</span></li>
                      <div class="buy_img"><img src="../../../public/img/mz.png"></div>
                    </el-popover>
                  </ul>
                </el-popover>
              </div>
            </el-col>
          </el-row> -->
          <!-- <el-row>
            <el-col :span="24">
              <div class="grid-content">
                <el-popover
                  trigger="hover"
                >
                  <el-button slot="reference" round>去登录</el-button>
                  <ul>
                    <li slot="reference"><a href="https://sinosc.chinasdc.cn/" target="_blank">全国心源性卒中基地工作平台</a></li>
                    <li><a href="http://is.prudencemed.com/login" target="_blank">越光信息管理系统</a></li>
                  </ul>
                </el-popover>
              </div>
            </el-col>
          </el-row> -->
        </div>
      </el-drawer>
      <!-- 2.menu部分 -->
      <div id="header_menu">
        <ul id="menu_list" :class="pathName=='/home'?'specialClass':''">
          <!-- 首页 -->
          <li class="menu_item " @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/home'}">首 页<hr></router-link></li>
          <!-- 简介 -->
          <!-- <li class="menu_item" @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/introduce'}">简 介<hr></router-link></li> -->
          <!-- <li class="menu_item" @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/bigthumb'}">越光大拇指<hr></router-link></li> -->
          <!-- 产品 -->
          <!-- <li class="menu_item" @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/product'}">产 品<hr></router-link></li> -->
          <!-- 支持 -->
          <!-- <li class="menu_item" @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/supports'}">支 持<hr></router-link></li> -->
          <!-- 资讯 -->
          <!-- <li class="menu_item" @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/news'}">资 讯<hr></router-link></li> -->
          <!-- 下载 -->
          <!-- <li class="menu_item" @click="dashed()"><router-link :class="pathName=='/home'?'specialClass':'defaultClass'" :to="{path:'/appdow'}">下 载<hr></router-link></li> -->
          <!-- 购买 按钮 -->
          <!-- <li class="menu_item btn">
            <el-popover
              trigger="hover"
            >
              <el-button slot="reference" round>购买</el-button>
              <ul>
                <el-popover
                  trigger="hover"
                  placement="left"
                >
                  <li slot="reference" class="li_buy"><span class="span_buy">动态心电检查</span></li>
                  <div class="buy_img"><img src="../../../public/img/xd.png"></div>
                </el-popover>
                <el-popover
                  trigger="hover"
                  placement="left"
                >
                  <li slot="reference" class="li_buy"><span class="span_buy">越光大拇指®</span></li>
                  <div class="buy_img"><img src="../../../public/img/mz.png"></div>
                </el-popover>
              </ul>
            </el-popover>
          </li> -->
          <!-- 登录 按钮 -->
          <!-- <li class="menu_item btn">
            <el-popover
              trigger="hover"
            >
              <el-button slot="reference" round>去登录</el-button>
              <ul>
                <li slot="reference"><a href="https://sinosc.chinasdc.cn/" target="_blank">全国心源性卒中基地工作平台</a></li>
                <li><a href="http://is.prudencemed.com/login" target="_blank">越光信息管理系统</a></li>
              </ul>
            </el-popover>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
body {
  outline: none;
	width: 100%;
	height: 100%;
	background-color: lightgray;
}

#header {
	z-index: 500;
	margin: 0px auto;
	width: 100%;
	background: transparent;
	-webkit-transition: top 0.35s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity 0.35s linear;
	-o-transition: top 0.35s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity 0.35s linear;
	transition: top 0.35s cubic-bezier(0.230, 1.000, 0.320, 1.000), opacity 0.35s linear;
}

/* 吸顶区navBarWrap */
.navBarWrap {
  overflow: hidden;
  min-height: 72px;
	color: black !important;
	background: #fff !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	border-bottom: 1px solid lightgray;
  position: fixed;
}
.span_buy:hover{
  cursor: pointer;
}
.drawer_main .el-row{
  margin: 15%;
  text-align: center;
}
.drawer_main .el-row a{
  color: #666666;
  text-decoration: none;
}
.navBarWrap a {
  text-shadow:none !important;
	text-decoration: none;
	color: black !important;
}
/* logo区 */
#header_logo {
	position: absolute;
	top: -12px;
	left: 5%;
  overflow: hidden;
}

#header_logo img {
	width: 88%;
}
#header_logo img:hover {
	cursor: pointer;
}
/* menu区 */
#header_menu {
  z-index: 1200;
	position: absolute;
	top: 36px;
	right: 40px;
}

.specialClass{
  color: white ;
  text-decoration: none;
}
.defaultClass{
  text-decoration: none;
	color: black;
}
#header_menu #menu_list {
	list-style: none;
}

#header_menu #menu_list .menu_item {
	width: 100px;
	text-align: center;
	height: 45px;
	float: left;
}

#header_menu #menu_list .menu_item hr {
	margin: 0px auto;
	width: 0%;
	margin-top: 2px;
	background-color: #009FF0;
	height: 3px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 0;
}

#header_menu #menu_list .menu_item:hover hr {
	-webkit-transition: width 0.15s linear 0s;
	-o-transition: width 0.15s linear 0s;
	transition: width 0.15s linear 0s;
	width: 40%;
}
/* menu 按钮区 */
.btn {
	margin-top: -8px;
}
.el-button{
  height:80%;
  width:87%;
  line-height: 0 !important;
}
.el-popover ul {
	list-style: none;
	overflow: hidden;
}
.el-popover ul li {
	text-align: center;
	padding: 5% 0px;
}

.el-popover ul li a {
	color: #009FF0 !important;
	text-decoration: none;
}

.buy_img {
	width: 128px;
	height: 128px;
	margin: 0 auto;
}
.drawer_main .el-button{
  height: 35px;
  width: 50%;
}
.buy_img img {
	width: 100%;
}
/* 手机图标 */
.open_list{
  display: none;
}
@media (max-width:1030px){
  #header_logo img{
    width: 50%;
  }
  #header_menu{
    display: none;
  }
  .navBarWrap{
    min-height: 25px;
  }
  .open_list{
    display: block;
    position:absolute;
    right: 10px;
    top:8px;
    height: 30px;
    width: 30px;
  }
  .open_list img{
    max-height: 100%;
    max-width: 100%;
  }
  .el-button{
    padding: 0px !important;
  }
}
</style>
<script>
export default {
  name: 'Layout',
  data() {
    return {
      dialog: false,
      navBarFixed: false,
      pathName: ''
    }
  },
  watch: {
    $route(to, from) {
      console.log('--->', to.path)
      this.pathName = to.path
    }
  },
  created() {
    this.pathName = this.$route.path
    console.log(this.pathName)
  },
  mounted() {
    window.addEventListener('scroll', this.watchScroll)
  },
  methods: {
    cancelForm() {
      this.loading = false
      this.dialog = false
      clearTimeout(this.timer)
    },
    // 点击头部logo跳转到首页
    logoUrl() {
      this.$router.push('/home')
    },
    // 控制滚动条头部吸顶菜单
    watchScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 20) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    },
    dashed() {
      this.dialog = false
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }

}
</script>

